@import "./assets/css/theme.css";
@import "./assets/css/customize.css";
@import "./assets/plugins/bootstrap/bootstrap.min.css";
@import "./assets/plugins/glightbox/glightbox.min.css";
@import "./assets/plugins/scrollcue/scrollcue.css";
@import "./assets/plugins/swiper/swiper-bundle.min.css";
@import "./assets/plugins/bootstrap-icons/bootstrap-icons.css";
@import "./assets/plugins/fontawesome/css/all.css";
@import "./assets/plugins/themify/themify-icons.css";
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
