.bg-image {
  background-image: url("../../assets/img/banner.jpg");
}

a {
  text-decoration: none !important;
}

#root::before {
  content: "";
  position: absolute;
  top: -200px;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../assets/svg/trongdong.svg");
  filter: sepia(1) hue-rotate(120deg) saturate(10) brightness(0.7);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top;
  opacity: 0.05;
  height: 250%;
  z-index: -1;
  pointer-events: none;
}

.marquee-container {
  display: flex;
}

.marquee-container > .marquee {
  margin-right: 70px !important;
  width: 400px;
}
